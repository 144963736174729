.sidebar-container {
    width: 256px;
    position: fixed;
    height: 100vh;
    z-index: 1;
}
.sidebar-logo {
    padding: 10px;
    width: 100%;
    cursor: pointer;
    @media (max-width: 454px) {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
.user-info {
    padding: 0px 34px;
    font-weight: bold;
}
