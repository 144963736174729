.popper {
    z-index: 3;
    position: relative;
    min-width: fit-content;
}

.popper-hidden {
    visibility: hidden;
    pointer-events: none;
}

#tooltip-top > .tooltip-inner,
#tooltip-right > .tooltip-inner,
#tooltip-bottom > .tooltip-inner,
#tooltip-left > .tooltip-inner {
    width: 100%;
    background-color: #f2f2f2;
    color: #000;
    border-radius: 0px;
}

.tooltip-icon-container {
    background-color: #009138;
    height: 15px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 15px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    margin-left: 5px;
    font-size: 13px;
}
