.input-container {
    border-bottom: 1px solid #f2f2f2 !important;
    height: 40px !important;
    .form-control {
        //border: 0px !important;
        height: auto !important;
        font-size: 15px !important;
        border: 1px solid #f2f2f2 !important;
        font-weight: normal !important;
    }

    .filter-border {
        border: none !important;
    }
}

.error-message {
    color: #ff0000 !important;
}
.text-field-svg-container {
    margin: 5px;
}
.password-icon {
    background-color: transparent !important;
}
