.breadcrumb-main-item {
    color: white !important;
    font-size: 12px;
    font-weight: bold;
}

.breadcrumb-secondary-item {
    color: white !important;
    font-size: 15px;
    font-weight: bold;
}

.breadcrumb-item + .breadcrumb-item::before {
    color: white !important;
    font-size: 15px !important;
}

.breadcrumb-item-cursor {
    cursor: pointer;
}
