@media (max-width: 768px) {
    .general-data-container {
        flex-direction: column;
        height: 900px;
    }
}

a.disabled {
    pointer-events: none;
    cursor: default;
}
