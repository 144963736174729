.card {
    display: flex;
    padding: 12px !important;
    box-shadow: 0px 5px 25px 0px rgba(31, 31, 31, 0.1) !important;
    border-radius: 4px !important;
}

.card::after {
    display: none !important;
}
