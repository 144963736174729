.select-container {
    border-bottom: 1px solid #f2f2f2 !important;
    height: 40px !important;
}

.filter-input-container {
    margin-bottom: 2rem !important;
}

.filter-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000;
}

.active {
    z-index: 0 !important;
    font-size: 15px !important;
}

.select-wrapper label,
p {
    color: #000 !important;
    font-size: 15px;
}

.label-color {
    color: #009138 !important;
}

.my-record-label {
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
}

.label-color:focus {
    color: #009138 !important;
    border: none !important;
}

input::placeholder {
    font-weight: normal !important;
}

.width-button-match {
    max-width: 125px !important;
    min-width: 90px !important;
    width: 100% !important;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url('../../../../_shared/assets/svg/it-expand.svg');
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-position-x: 100%;
    background-position-y: 5px;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    margin-right: 2rem;
    padding: 1rem;
    padding-right: 2rem;
}

.select-wrapper select {
    color: #000 !important;
    font-weight: normal !important;
    font-size: 15px;
}

.select-wrapper label,
p {
    color: #000 !important;
    font-size: 15px;
}

.select-wrapper label {
    color: #009138 !important;
}
