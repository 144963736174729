.bacoffice-iframe-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.iframe {
    width: 100% !important;
    border: none;
}
