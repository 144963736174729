.btn-group {
    .btn {
        .arrow-up {
            display: none;
        }
    }

    .btn:focus[aria-expanded='true'] {
        .arrow-down {
            display: none;
        }

        .arrow-up {
            display: block;
        }
    }

    .btn:focus[aria-expanded='false'] {
        .arrow-up {
            display: none;
        }

        .arrow-down {
            display: block;
        }
    }
}

.dropdown-menu[data-popper-placement='bottom-end']:before {
    display: none !important;
}

.dropdown-menu:before {
    display: none !important;
}
