.link {
    color: #000;
    text-decoration: none;
    font-size: 12px;
}

.linkBarContainer {
    display: flex;
    margin-left: auto;
}
