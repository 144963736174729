//Per l'override del colore $primary della palette in formato HSB (colore #FF3333 https://rgb.to/ff3333):

//$primary: #009138;

$primary-h: 141;
$primary-s: 100;
$primary-b: 57;

// $btn-secondary: #666666;
// $btn-secondary: #666666;
// $btn-bg-secondary-h: 0;
// $btn-bg-secondary-s: 0;
// $btn-bg-secondary-b: 40;

$theme-colors: (
    'primary': #009138,
    'secondary': #666666,
);

// Per l'override della famiglia di caratteri
$font-family-serif: 'Titillium Web', sans-serif;
$font-family-sans-serif: 'Titillium Web', sans-serif;
$font-family-monospace: 'Titillium Web', sans-serif;

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
input[type='checkbox']:focus,
.uneditable-input:focus {
    box-shadow: none !important;
    outline: 0 none !important;
}

//Body color

// $bs-body-color-h:0;
// $bs-body-color-s:0;
// $bs-body-color-b:40;

//$bs-body-color: #000;;

// import libreria
@import '../../../node_modules/bootstrap-italia/src/scss/bootstrap-italia.scss';
