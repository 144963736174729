.position-button-metadata {
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: space-between !important;
}

.provisioning-ide-component {
    height: 750px !important;
    width: 100% !important;
}
