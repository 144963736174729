.no-image-size-detail {
    height: 165px;
    min-width: 165px;
    border-radius: 50%;
    background-color: #d9d9d9;
    @media (max-width: 661px) {
        margin: 12px;
    }
}

.wrap-no-image {
    @media (max-width: 661px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
