table > thead > tr > th {
    // text-transform: uppercase;
    background-color: #f7f7f7 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    vertical-align: middle;
    height: 36px;
    color: #009138 !important;
    border-bottom: 2px solid #dee2e6 !important;
    font-weight: 400 !important;
    font-size: 15px;
}

.styledHeaders {
    background-color: #fafbfc;
    font-size: 15px !important;
    font-weight: bold !important;
}

.clickedRow {
    background-color: hsla(225, 4%, 90%, 1);
}

table > tbody > tr {
    vertical-align: middle;
    color: #000;
    cursor: pointer;
    td {
        width: max-content;
        font-size: 15px;
    }
    border-bottom: 1px solid #dee2e6 !important;
}

.collapse-row-command {
    border-radius: 100% !important;
}

.table-padding {
    padding: 0.5rem 0.5rem 0.5rem 1.5rem !important;
    font-size: 15px;
    font-weight: 400;
    color: #000;
}

.collapse-row-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.table-row {
    border-bottom: transparent !important;
}

tr:hover {
    background-color: transparent !important;
    border-bottom: transparent !important;
}

tbody:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-size-title {
    font-size: 15px;
    font-weight: bold !important;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem !important;
}

.table > :not(:first-child) {
    border-top: 1px solid #dee2e6 !important;
}
