$rhap_theme-color: #009138 !important;

.rhap_main-controls-button,
.rhap_volume-button,
.rhap_repeat-button {
    color: $rhap_theme-color;
}

.rhap_volume-indicator,
.rhap_progress-indicator {
    background: $rhap_theme-color;
}
