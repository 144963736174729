.circle-container-detail {
    border-radius: 50%;
    display: flex;
    height: 65px !important;
    align-self: center;
    width: 65px !important;
    margin: 0px 10px;
    align-items: center;
    justify-content: center;
}

.circle-container-color-high {
    background-color: #009138;
    color: white !important;
}

.circle-container-color-medium {
    background-color: white;
    color: #009138 !important;
    border-radius: 50%;
    border: 2px solid #009138;
}

.circle-container-color-low {
    background-color: rgba(128, 128, 128, 0.3);
    color: black !important;
}

.width-button-match {
    max-width: 135px;
    min-width: 110px;
    width: 100% !important;
}
