.home-login-container {
    background-color: #f2f2f2;
    display: flex !important;
    align-items: flex-end !important;
    padding-bottom: 10px;
}

.login-container {
    background-color: #009138;
    display: flex !important;
    align-items: flex-end !important;
    padding-bottom: 10px;
}
.login-link-container {
    margin-top: 1rem !important;
    margin-bottom: 2.8px;
    margin-left: auto;
}

.log-in-out-text {
    color: #009138 !important;
    text-decoration: none;
    padding: 5px 22px;
    cursor: pointer;
}

.home-login-text-container {
    padding: 0 20px;
    line-height: 30px;

    background-color: #009138;
    color: #ffffff;
    margin-right: 20px;
    border-radius: 15px;
    display: inline-block;
    font-size: 15px;
}

.login-text-container {
    padding: 0 20px;
    line-height: 30px;
    background-color: #ffffff;
    color: #009138;
    margin-right: 20px;
    border-radius: 15px;
    display: inline-block;
    font-size: 15px;
}

.home-logout-text-container {
    color: #009138;
    font-size: 15px;
}

.logout-text-container {
    color: #ffffff;
    font-size: 15px;
}

.logout-cursor {
    cursor: pointer;
}
