.tabButton {
    padding: 18px 25px 18px 24px;
    margin-top: 15px;
    margin-bottom: 14px;
    margin-left: 20px;
    color: black;
}

.nav-link {
    font-size: 15px !important;
}
