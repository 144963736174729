.dropdown-menu-container {
    transform: rotateY(180deg);
    right: 0px !important;
    left: auto !important;

    div {
        transform: rotateY(-180deg);
    }
}

.user-toggle {
    display: flex;
    flex-direction: column;
    p {
        margin-bottom: 0px;
        text-transform: capitalize;
    }
}
