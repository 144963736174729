.navigation-container {
    margin-top: 40px;
}

.link-container {
    margin-top: 2.8px !important;
    margin-bottom: 2.8px;
    margin-left: auto;
}

.link {
    color: #000;
    text-decoration: none;
    padding: 5px 22px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px !important;
}
.link.active {
    color: #009138 !important;
    font-weight: 600;
}
