.previous-next-container {
    width: 14px;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 4px;
}

.page-item {
    height: 32px;
    width: 32px;
    margin-left: 1rem;
    cursor: pointer;
}

// .page-link-active {
//     border: none;
//     color: white;
//     margin-top: 12px;
//     background-color: #009138;
//     border-radius: 3px;
//     height: 32px;
//     width: 32px;
// }

// .page-link-non-active {
//     border: none;
//     margin-top: 12px;
//     background-color: white;
//     color: #009138;
//     height: 32px;
//     width: 32px;
// }

.page-item.active .page-link {
    background-color: #009138 !important;
    color: white !important;
    border-color: #009138 !important;
    z-index: 3;
    pointer-events: all !important;
}

.pagination .page-link {
    height: 32px !important;
    width: 32px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-color: transparent;
    border-radius: 3px;
    margin-left: 0.1em;
    margin-right: 0.1em;
}

a:not([href]) {
    // color: inherit;
    text-decoration: none;
}

.page-link {
    position: relative;
    display: block;
    padding: 0 0;
    margin-left: -1px;
    line-height: 1.25;
    color: #888;
    background-color: white !important;
    border: none !important;
}
