.tableTitle {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;
    margin-top: 16px !important;
}

.header-container {
    line-height: 1;
    background-color: #f2f2f2;
    padding: 1vw 2vw;
    margin-bottom: 1rem;
    align-items: center;
    display: flex;
}

button:focus,
button:focus:not(.focus--mouse) {
    border: none !important;
    box-shadow: none !important;
}

.position-detail-element {
    justify-content: start;
    padding-left: 24px !important;
}

.width-element-header {
    max-width: min-content !important;
}

.text-header-detail {
    font-weight: 400;
    font-size: 15px;
    color: black !important;
}

.text-header {
    font-weight: 600;
    font-size: 15px;
    overflow-wrap: anywhere;
}

.inline-text {
    overflow: visible;
    display: inline !important;
}

.white-space-nowrap {
    white-space: nowrap !important;
}

.container-gray {
    background-color: #f2f2f2;
    padding-bottom: 10px;
    padding-top: 10px;
}

.separator-detail {
    @media (min-width: 992px) {
        display: inline !important;
    }

    @media (max-width: 992px) {
        display: none;
    }
}

.align-item-header-detail {
    align-self: flex-start;
}

.button:focus,
.button:focus:not(.focus--mouse) {
    border: none !important;
    box-shadow: none !important;
}

.speces-text-detail-size {
    font-weight: bold;
    color: #009138 !important;
}

.align-first-image {
    text-align: -webkit-center;
    text-align: -moz-center;
    align-self: center;
}

.div-first-image {
    @media (min-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: max-content;
    }

    @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: max-content;
    }
}

.display-none-img1 {
    @media (max-width: 576px) {
        display: none !important;
    }
}

.display-none-img2 {
    @media (max-width: 750px) {
        display: none !important;
    }
}

.recognition-button {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;

    button {
        margin-right: 2%;
        margin-left: 2%;
    }
}

.margin-button-prev {
    margin-left: 100px !important;
}

.margin-button-next {
    margin-right: 100px !important;
}

.image-size-detail {
    height: 165px;
    width: 165px;
    border-radius: 50%;
}

.carousel-button {
    margin-left: 10px;
    margin-right: 10px;
}

.image-detail-carousel {
    border-radius: 50%;
    margin: 5px 15px;
    padding: 0px !important;
    height: 135px !important;
    width: 135px !important;
}

.display-banner {
    display: flex;
    flex-direction: row;
}

.size-audioPlayer {
    @media (min-width: 686px) {
        width: 60%;
        padding: 20px;
    }

    @media (max-width: 686px) {
        width: 100%;
        padding: 20px;
    }
}

.recognitionButton {
    flex-wrap: wrap !important;
    align-content: center !important;
}

.link-item {
    cursor: pointer;
}

.dropdown-menu:focus,
.dropdown-menu:focus:not(.focus--mouse) {
    border: none !important;
    box-shadow: none !important;
}

.rhap_progress-container:focus,
.rhap_progress-container:focus:not(.focus--mouse),
.rhap_volume-bar-area:focus,
.rhap_volume-bar-area:focus:not(.focus--mouse),
.rhap_container:focus,
.rhap_container:focus:not(.focus--mouse) {
    border: none !important;
    box-shadow: none !important;
}

.table-status-label {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    color: #000000;
}
