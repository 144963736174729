.vr {
    margin: 24px;
}

.navbar-container {
    box-shadow: 0px 2px 5px rgba(31, 31, 31, 0.2);
    top: 0;
    position: sticky !important;
    z-index: 1;
}

.linkbar-container {
    display: flex;
    margin-left: auto;
}
.userWidget-container {
    display: flex;
    align-items: center;
}

.logo {
    cursor: pointer;
    @media (min-width: 992px) {
        padding-left: 24px;
    }
    @media (max-width: 454px) {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.size-tab {
    z-index: 3;
    position: relative;
    container-type: inline-size;
    overflow: auto;
}

.navbar-nav {
    display: flex;
    margin-left: auto;
}
.divider {
    height: 1px;
    display: block;
    background: #ebeced;
    margin: 8px 0;
}
.show {
    border: 0px !important;
}

.size-tab {
    z-index: 3;
    position: relative;
    overflow: auto;
}
